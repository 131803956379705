<template>
  <InfoWraper>


  </InfoWraper>
</template>

<script>
import {InfoWraper} from "./auth-info-style";
import {useStore} from "vuex";
import {useRouter} from "vue-router";
import {computed, defineComponent, onMounted} from "vue";

export default defineComponent({
  name: "AuthInfo",
  components: {
    InfoWraper,

  },
  data() {
    return {
      flag: "english",
    };
  },
  setup() {
    const {dispatch, state} = useStore();
    const router = useRouter();
    const profile = computed(() => state.profile.profile);
    const isLoading = computed(() => state.auth.loading);

    const logOutRoot = () => {
      router.push({name: 'login'});
    };

    const SignOut = (e) => {
      e.preventDefault();
      dispatch("logout", logOutRoot);
    };

    onMounted(async () => {
      // await dispatch('getProfile');
    });

    return {
      SignOut,
      isLoading,
      profile,
    };
  },
  methods: {
    onFlagChangeHandle: function (value) {
      this.flag = value;
    },
  },
});
</script>
